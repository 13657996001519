.incident-container {
  margin: 24px;
  padding: 20px;
}

.datetime {
  font-size: 24px;
  color: #3d5466;
}

.item-container {
  margin-bottom: 40px;
}

.endpoint {
  font-size: 14px;
  flex-direction: column;
}

b {
  color: #3d5466;
}
