.invoice-container {
  margin-top: 32px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

table {
  width: 100%;
}

th,
td {
  padding: 8px;
}

th {
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

ul {
  margin: 0;
  padding-left: 20px;
}
